












































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import { namespace } from 'vuex-class'

import ServicesByRegionalField from '@/partials/forms/components/ServicesByRegional.vue'
import NotificationMixin from '@/mixins/notificationMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'

const sessionModule = namespace('session')

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ServicesByRegionalField,
    NotifyOnFailed,
    PasswordConfirmField
  }
})
export default class ServiceMessageCreate extends Mixins(NotificationMixin, FlashMessageMixin, ValidatorConfigMixin) {
  @sessionModule.Getter state!: sessionState

  form: Record<string, any> = {
    message: '',
    senderId: '',
    subject: '',
    services: [],
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  done = true

  submit () {
    this.done = false

    const submitForm = snakeCaseKeys(this.form)
    submitForm.services = submitForm.services.flat()

    axios.post('/services/message', submitForm)
      .then(response => {
        const flashMessage = {
          message: response.data.data,
          isSuccess: true
        }
        this.$router.push({ name: 'ServiceMessages' }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  created () {
    this.form.senderId = this.state.user.id
  }
}
