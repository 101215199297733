var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"px-4"},[_c('router-link',{attrs:{"to":{name:'ServiceMessages'}}},[_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"kind":"secondary"}},[_vm._v(" Voltar para Lista de Comunicados ")])],1),_c('div',{staticClass:"flex justify-center my-8"},[_c('ValidationObserver',{staticClass:"w-full px-3 lg:w-1/2 xl:w-1/3 list-mt-6",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl"},[_vm._v(" Enviar Comunicado ")]),_c('ValidationProvider',{attrs:{"name":"subject","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Assunto/Título","invalid-message":errors[0]},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"message","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-area',{attrs:{"label":"Mensagem","helper-text":"Digite a mensagem que será enviada para o Centro de Serviço","invalid-message":errors[0],"rows":"5"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ServicesByRegionalField',{model:{value:(_vm.form.services),callback:function ($$v) {_vm.$set(_vm.form, "services", $$v)},expression:"form.services"}},[(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione pelo menos 1 ")]):_vm._e()])]}}],null,true)}),_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),(_vm.notification.open)?_c('cv-inline-notification',{attrs:{"kind":_vm.notification.kind,"title":_vm.notification.title,"sub-title":_vm.notification.message,"close-aria-label":"Fechar","low-contrast":false},on:{"close":function($event){_vm.notification.open = false}}}):_vm._e(),_c('div',{staticClass:"mt-2"},[(_vm.done)?_c('cv-button',{on:{"click":function($event){_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_vm._v(" Enviar Comunicado ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }